import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { checkSelectedBusinessRpaMode } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import CommonMobileHeader from "mlib/common/MobileHeader";
import DateRangePickerExtended from "mlib/common/DateRangePickerExtended";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const {
  TYPES: {
    RPA_STARTED,
    RPA_FINISHED,
    RPA_FAILED,
    GPT_AI_FINE_TUNE_STARTED,
    GPT_AI_FINE_TUNE_FINISHED,
    GPT_AI_FINE_TUNE_FAILED,
    TRANSACTIONS_CATEGORIZED,
    TRANSACTIONS_NOT_CATEGORIZED,
    TRANSACTIONS_AUTO_CATEGORIZED,
    TRANSACTIONS_AUTO_APPROVED,
    TRANSACTIONS_AUTO_ASK_CLIENT,
    TASK_MARKED_AS_COMPLETED
  }
} = ActivityEvents;

const EVENT_TYPE_OPTIONS = Object.values(ActivityEvents.TYPES);

const RPA_EVENTS = [
  RPA_STARTED,
  RPA_FINISHED,
  RPA_FAILED,
  GPT_AI_FINE_TUNE_STARTED,
  GPT_AI_FINE_TUNE_FINISHED,
  GPT_AI_FINE_TUNE_FAILED,
  TRANSACTIONS_CATEGORIZED,
  TRANSACTIONS_NOT_CATEGORIZED,
  TRANSACTIONS_AUTO_CATEGORIZED,
  TRANSACTIONS_AUTO_APPROVED,
  TRANSACTIONS_AUTO_ASK_CLIENT
];

const MobileHeader = () => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const {
    fromDate,
    toDate,
    type
  } = envVars;

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const [opened, setOpened] = useState(false);

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const filtersActive = [fromDate, toDate, type].find(Boolean);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    ...Utils.arraySort(
      EVENT_TYPE_OPTIONS
        .filter((eventType) => TASK_MARKED_AS_COMPLETED !== eventType)
        .filter((eventType) => rpaMode || !RPA_EVENTS.includes(eventType))
        .map((eventType) => ({ value: eventType, label: uiTexts[eventType] || eventType })),
      "label",
      "asc"
    )
  ], [rpaMode, type, uiTexts]);

  const renderSelectLabel = useCallback((label) => {
    return (
      <div className={Css.selectToggleButton}>
        <Icons.CreditCard />
        <span className={Css.title}>
          {uiTexts.type}
        </span>
        <span
          className={classNames(Css.label, label && Css.hasSelected)}>
          {label || uiTexts.selectType}
        </span>
      </div>
    );
  }, [uiTexts]);

  const handleDateChange = useCallback((value) => {
    const [first, second] = value;

    setEnvVars({ fromDate: first, toDate: second });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value });
  }, [setEnvVars]);

  const handleResetClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, type: null });
    setOpened(false);
  }, [setEnvVars]);

  const handleDropDownToggle = useCallback(() => {
    setOpened((prev) => !prev);
  }, []);

  return (
    <>
      <CommonMobileHeader active={filtersActive} className={Css.mobileHeader}>
        <Button
          outline
          primary={filtersActive}
          className={Css.moreFilters}
          onClick={handleDropDownToggle}>
          <Icons.Funnel />
        </Button>
      </CommonMobileHeader>
      {opened && (
        <Modal
          title={uiTexts.filters}
          className={Css.filtersModal}
          onClose={handleDropDownToggle}>
          <div className={Css.list}>
            <div className={Css.listItem}>
              <DateRangePickerExtended
                className={Css.datePicker}
                value={fromToDate}
                placeholder={uiTexts.selectDate}
                label={uiTexts.dateRange}
                onChange={handleDateChange} />
            </div>
            <div className={Css.listItem}>
              <Select
                modal
                active={!!type}
                className={Css.select}
                value={type}
                placeholder={uiTexts.selectType}
                options={typeOptions}
                renderLabel={renderSelectLabel}
                onChange={handleTypeChange} />
            </div>
          </div>
          <div className={Css.buttons}>
            <Button
              primary
              outline
              disabled={!filtersActive}
              className={Css.button}
              onClick={handleResetClick}>
              <Icons.X /> {uiTexts.resetAll}
            </Button>
            <Button light className={Css.button} onClick={handleDropDownToggle}>
              {uiTexts.close}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(MobileHeader);
