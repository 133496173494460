import Css from "./style.module.scss";

import { Redirect, useHistory } from "react-router-dom";
import { checkActivityFetching } from "selectors/activity";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserRole } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import ActivityActions from "actions/ActivityActions";
import ActivityItem from "./lib/ActivityItem";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Countries from "const/Countries";
import Filters from "./lib/Filters";
import MobileHeader from "./lib/MobileHeader";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const ACTIVITY_PAGE_SIZE = 20;

const ActivityPage = ({ className }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [envVars] = useEnvVars();

  const prevEnvVarsRef = useRef({});

  const activityFetching = useSelector(checkActivityFetching);

  const [activityData, setActivityData] = useState(null);

  const [showMoreShown, setShowMoreShown] = useState(false);

  const { uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const { countryCode } = useSelector(getActiveOrganization);

  const userRole = useSelector(getUserRole);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const [currentPage, setCurrentPage] = useState(1);

  const czCountry = countryCode === Countries.CZ;

  const filtersActive = [envVars.fromDate, envVars.toDate, envVars.type].find(Boolean);

  const todayData = activityData && activityData.filter(({ timestamp }) => moment(timestamp).isSame(new Date(), "day"));

  const thisMonthData = activityData && activityData.filter(({ timestamp }) => {
    return !moment(timestamp).isSame(new Date(), "day") && moment(timestamp).isSame(new Date(), "month");
  });

  const earlyData = activityData && activityData.filter(({ timestamp }) => {
    return !moment(timestamp).isSame(new Date(), "month");
  });

  const handleShowMoreClick = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, []);

  const updateItems = useCallback(async(page = 0) => {
    const result = await dispatch(
      ActivityActions.fetchActivityList({
        backgroundUpdate: true,
        fromDate: envVars.fromDate,
        toDate: envVars.toDate,
        type: envVars.type,
        limit: ACTIVITY_PAGE_SIZE,
        offset: page * ACTIVITY_PAGE_SIZE
      })
    );

    if (Array.isArray(result)) {
      const newItems = result.filter(({ id }) => !Utils.arrayFindById(activityData, id));

      setActivityData([...newItems, ...(activityData || [])]);
      if (newItems.length >= ACTIVITY_PAGE_SIZE) {
        updateItems(page + 1);
      }
    }
  }, [activityData, envVars.fromDate, envVars.toDate, envVars.type, dispatch]);

  useEffect(() => {
    return Utils.setTimeout(() => updateItems(), Constants.DATA_LIST_UPDATE_INTERVAL);
  }, [updateItems]);

  useEffect(() => {
    if (czCountry && businessUser) return;

    const prevVars = prevEnvVarsRef.current;

    const filtersChanged = prevVars.fromDate !== envVars.fromDate
      || prevVars.toDate !== envVars.toDate
      || prevVars.type !== envVars.type;

    prevEnvVarsRef.current = {
      fromDate: envVars.fromDate,
      toDate: envVars.toDate,
      type: envVars.type
    };

    if (filtersChanged && currentPage !== 1) {
      setCurrentPage(1);
      setActivityData([]);
    } else {
      dispatch(
        ActivityActions.fetchActivityList({
          clearList: filtersChanged,
          fromDate: envVars.fromDate,
          toDate: envVars.toDate,
          type: envVars.type,
          limit: ACTIVITY_PAGE_SIZE,
          offset: (currentPage - 1) * ACTIVITY_PAGE_SIZE
        })
      ).then((result) => {
        if (Array.isArray(result)) {
          setShowMoreShown(result.length === ACTIVITY_PAGE_SIZE);
          setActivityData((prev) => filtersChanged ? result : [
            ...(prev || []),
            ...result.filter((item) => !Utils.arrayFindById(prev, item.id))
          ]);
        }
      });
    }
  }, [
    businessUser,
    currentPage,
    czCountry,
    dispatch,
    history,
    envVars.fromDate,
    envVars.toDate,
    envVars.type,
    selectedBusinessId
  ]);

  if (czCountry && businessUser) {
    return <Redirect to={`/${selectedBusinessId}${UiRoutes.TRANSACTIONS}`} />;
  }

  return (
    <>
      {Utils.checkIsTouchDevice() && <MobileHeader />}
      <Page className={classNames(Css.activityPage, className)}>
        <PageContent>
          {!Utils.checkIsTouchDevice() && (
            <>
              <PageHeader />
              <Filters disabled={activityFetching} />
            </>
          )}
          {activityData?.length
            ? (
              <>
                {!!todayData.length && (
                  <div className={Css.container}>
                    <div className={Css.section}>
                      <div className={Css.title}>{uiTexts.today}</div>
                      {todayData.map((item) => <ActivityItem key={item.id} item={item} />)}
                    </div>
                  </div>
                )}
                {!!thisMonthData.length && (
                  <div className={Css.container}>
                    <div className={Css.section}>
                      <div className={Css.title}>{uiTexts.thisMonth}</div>
                      {thisMonthData.map((item) => <ActivityItem key={item.id} item={item} />)}
                    </div>
                  </div>
                )}
                {!!earlyData.length && (
                  <div className={Css.container}>
                    <div className={Css.section}>
                      <div className={Css.title}>{uiTexts.early}</div>
                      {earlyData.map((item) => <ActivityItem key={item.id} item={item} />)}
                    </div>
                  </div>
                )}
                {showMoreShown && (
                  <div className={Css.showMore} onClick={handleShowMoreClick}>
                    <Button large outline disabled={activityFetching}>{uiTexts.showMore}</Button>
                  </div>
                )}
              </>
            )
            : (
              <div className={Css.emptyState}>
                {(activityFetching || !activityData) ? <Preloader /> : (
                  <NoDataContent
                    title={filtersActive ? uiTexts.nothingFound : uiTexts.noActivity} />
                )}
              </div>
            )}
        </PageContent>
      </Page>
    </>
  );
};

export default React.memo(ActivityPage);
